import request from '@/utils/request';

const PATH = '/reservation-item/hour';

export const getReservationItemHour = (itemUid: string, params: any) => request({
  url: `${PATH}/${itemUid}`,
  method: 'get',
  params,
});

export const getReservationItemHourInfo = (itemUid: string, params: any) => request({
  url: `${PATH}/${itemUid}/info`,
  method: 'get',
  params,
});
