
import moment from 'moment';
import { Component, Vue, Watch } from 'vue-property-decorator';
import Calendar from '@/components/Calendar/index.vue';
import { getReservationCalendar, getReservationItem } from '@/api/reservationItem';
import { getReservationItemHour } from '@/api/reservationItemHour';
import { getReservationItemDayInfo } from '@/api/reservationItemDay';
import { setReservation } from '@/utils/cookies';

@Component({
  components: {
    Calendar,
  },
})

export default class extends Vue {
  mounted() {
    this.getReservationCalendar();
    this.getReservationItem();
  }

  private people = 1;

  private selectedHourUid = '';

  private reservationItem = {
    address: '',
    addressDetail: null,
    approvalStatus: false,
    callStatus: true,
    category: { uid: '', name: '' },
    continueStatus: true,
    detailDescription: '',
    directStatus: true,
    facilityCategoryName: null,
    formFileUid: null,
    freeStatus: false,
    hourUnitStatus: false,
    images: [],
    inquiryNumber: '',
    managerName: '',
    maxPeople: '',
    name: '',
    onlineStatus: true,
    postCode: '',
    price: 0,
    rangeInfiniteStatus: true,
    reserveEnable: false,
    restriction: '',
    settingUnitStatus: null,
    type: { uid: '', name: '' },
    uid: '',
  }

  private hourList: any = []; // 회차 선택 목록

  private hourLoading = false;

  private calendarLoading = true;

  private selectedDay: string | null = '';

  private selectedMonth = moment().format('YYYY-MM');

  private calendarData = []; // 달력 일별 데이터

  private dayInfo = {
    useDate: '',
    reserveCount: 0,
    maxPeople: 0,
    price: 0,
  };

  private dayInfoLoading = false;

  private getReservationItem() {
    getReservationItem(this.$route.params.itemUid).then((res) => {
      this.reservationItem = res.data;
    });
  }

  private handleChoiceDate(startDate: string, endDate: string) {
    this.selectedHourUid = '';
    this.selectedDay = startDate;
    if (this.reservationItem.settingUnitStatus === 0) {
      this.getReservationItemDay();
    } else if (this.reservationItem.settingUnitStatus === 1) {
      this.getReservationItemHour();
    }
  }

  private getReservationCalendar() {
    this.calendarLoading = true;
    getReservationCalendar(this.$route.params.itemUid, { selectedMonth: `${this.selectedMonth}` }).then((res) => {
      this.calendarData = res.data;
      this.calendarLoading = false;
    });
  }

  private handleChangeMonth(yearMonth: any) {
    this.selectedMonth = moment(yearMonth).format('YYYY-MM');
    this.getReservationCalendar();
  }

  private getReservationItemHour() {
    this.hourLoading = true;
    getReservationItemHour(this.reservationItem.uid, { searchDate: this.selectedDay }).then((res) => {
      this.hourList = res.data;
      this.hourLoading = false;
    });
  }

  private getReservationItemDay() {
    this.dayInfoLoading = true;
    getReservationItemDayInfo(this.reservationItem.uid, { useDate: this.selectedDay }).then((res) => {
      this.dayInfoLoading = false;
      this.dayInfo = res.data;
    });
  }

  private handleReserve() {
    setReservation({
      hourUid: this.selectedHourUid,
      useDate: this.selectedDay,
      people: this.people,
    });
    this.$router.push({
      name: 'PlaceReserve',
      params: {
        idx: this.$route.params.idx,
        itemUid: this.$route.params.itemUid,
      },
    });
  }

  private handleReset() {
    this.people = 1;
    this.selectedHourUid = '';
    this.selectedDay = '';
    this.hourList = [];
    this.dayInfo = {
      useDate: '',
      reserveCount: 0,
      maxPeople: 0,
      price: 0,
    };
    const calendarRef: any = this.$refs.calendar;
    if (calendarRef) calendarRef.clearClickClass();
  }

  private getMaxPeople() {
    let maxPeople = 1;
    if (this.selectedHourUid) {
      const hourIndex = this.hourList.findIndex((hour: any) => hour.uid === this.selectedHourUid);
      if (hourIndex > -1) {
        maxPeople = this.hourList[hourIndex].maxPeople - this.hourList[hourIndex].reserveCount;
      }
    }
    return maxPeople;
  }
}
