import request from '@/utils/request';

const PATH = '/reservation-item';

export const getReservationItemList = (params: any) => request({
  url: PATH,
  method: 'get',
  params: {
    ...params,
    page: params.page - 1,
  },
});

export const getReservationItem = (uid: string) => request({
  url: `${PATH}/${uid}`,
  method: 'get',
});

export const getReservationCalendar = (uid: string, params: any) => request({
  url: `${PATH}/${uid}/calendar`,
  method: 'get',
  params,
});

export const getReservationItemForm = (uid: string, data: any) => request({
  url: `${PATH}/${uid}/form`,
  method: 'post',
  data,
});
